import axios from 'axios'
import { instance, urlEndpoints } from './apiHelper'

async function upsertSchedule(data) {
   const result = await instance.put(urlEndpoints.SCHEDULE, data)
   return result.data
}

async function deleteScheduleShifts(data) {
   const result = await instance.delete(urlEndpoints.SCHEDULE, data)
   return result.data
}

async function upsertCrews(data) {
   const result = await instance.put(urlEndpoints.CREW_UPSERT, data)
   return result.data
}

async function deleteCrew(data) {
   const result = await instance.delete(urlEndpoints.CREW_UPSERT, data)
   return result.data
}

async function getFinancialsData(params) {
   const result = await instance.get(urlEndpoints.PLANT_FINANCIALS, { params })
   return result.data
}

async function submitFinancialsData(data) {
   const result = await instance.put(urlEndpoints.PLANT_FINANCIALS, data)
   return result.data
}
async function getGeneralConfigStatus(params) {
   const result = await instance.get(urlEndpoints.GENERAL_CONFIG_STATUS, {
      params,
   })
   return result.data
}
async function updateGeneralConfigStatus(data) {
   const result = await instance.put(urlEndpoints.GENERAL_CONFIG_STATUS, data)
   return result.data
}

async function getRotations(params) {
   const result = await instance.get(urlEndpoints.ROTATION, { params })
   return result.data
}

async function updateRotations(data) {
   const result = await instance.put(urlEndpoints.ROTATION, data)
   return result.data
}

async function deleteRotations(data) {
   const result = await instance.delete(urlEndpoints.ROTATION, data)
   return result.data
}
async function getWeeklyRotationsExportData(params) {
   const result = await instance.get(urlEndpoints.EXPORT_WEEKLY_DATA, {
      params,
   })
   return result.data
}
async function getUploadPresignedUrl(params) {
   const result = await instance.post(
      urlEndpoints.EQUIPMENT_INTELLIGENCE_GET_PRESIGNED_URL,
      {
         ...params,
      }
   )
   return result.data
}
async function uploadToPresignedUrl(presignedUrl, file) {
   return axios({
      url: presignedUrl,
      method: 'put',
      data: file,
      headers: { 'Content-Type': file.type },
   })
}
async function processWeeklyRotationsUpload(data) {
   const result = await instance.post(
      urlEndpoints.WEEKLY_ROTATIONS_PROCESS,
      data
   )
   return result.data
}
async function fetchWeeklyRotations(params) {
   const result = await instance.get(urlEndpoints.WEEKLY_ROTATIONS, {
      params,
   })
   return result.data
}
async function fetchDailyRotations(params) {
   const result = await instance.get(urlEndpoints.DAILY_ROTATIONS, {
      params,
   })
   return result.data
}
async function fetchDailyRotationsExportData(params) {
   const result = await instance.get(urlEndpoints.EXPORT_DAILY_DATA, {
      params,
   })
   return result.data
}

async function updateWeeklyRotations(data) {
   const result = await instance.put(urlEndpoints.WEEKLY_ROTATIONS, data)
   return result.data
}

async function updateDailyRotations(data) {
   const result = await instance.put(urlEndpoints.DAILY_ROTATIONS, data)
   return result.data
}
async function discradFactoryGeneral(data) {
   const result = await instance.put(urlEndpoints.FACTORY_CONFIG_DISCARD, data)
   return result.data
}
async function getEquipmentHierarchyData(params) {
   const result = await instance.get(urlEndpoints.GET_ENTITY_HIERARCHY, {
      params,
   })
   return result.data
}
async function getSchedule(params) {
   const result = await instance.get(urlEndpoints.NEW_GET_SCHEDULE, { params })
   return result.data
}

async function getPlantTimezone(params) {
   const result = await instance.get(urlEndpoints.GET_FACTORY_TIMEZONE, { params })
   return result.data;
 }

export const factoryGeneralConfig = {
   upsertCrews,
   deleteCrew,
   upsertSchedule,
   deleteScheduleShifts,
   getFinancialsData,
   submitFinancialsData,
   getGeneralConfigStatus,
   updateGeneralConfigStatus,
   getRotations,
   updateRotations,
   deleteRotations,
   getWeeklyRotationsExportData,
   getUploadPresignedUrl,
   uploadToPresignedUrl,
   processWeeklyRotationsUpload,
   fetchWeeklyRotations,
   fetchDailyRotations,
   fetchDailyRotationsExportData,
   updateWeeklyRotations,
   updateDailyRotations,
   discradFactoryGeneral,
   getEquipmentHierarchyData,
   getSchedule,
   getPlantTimezone,
}
