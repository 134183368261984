import { instance, urlEndpoints } from './apiHelper'

const getActiveTriggersForPlant = async (plantId) => {
   const result = await instance.get(
      urlEndpoints.NOTIFICATION_ACTIVE_TRIGGERS,
      { params: { plantId } }
   )
   return result.data
}

const getAllRolesForPlant = async (plantId) => {
   const result = await instance.get(urlEndpoints.NOTIFICATION_ROLES, {
      params: { plantId },
   })
   return result.data
}

const getNotificationTimings = async (plantId) => {
   const result = await instance.get(
      `${urlEndpoints.NOTIFICATION_TIMING}?plantId=${plantId}`
   )
   return result.data
}

const updateNotificationTimings = async (body) => {
   const result = await instance.put(urlEndpoints.NOTIFICATION_TIMING, body)
   return result.data
}

const getNotificationTriggersList = async (plantId) => {
   const result = await instance.get(urlEndpoints.NOTIFICATION_TRIGGERS_LIST, {
      params: { plantId },
   })
   return result.data
}

const saveTriggersForPlant = async (body) => {
   const result = await instance.post(urlEndpoints.SAVE_NOTIFICATIONS, body)
   return result.data
}

const getAllNotificationsForPlant = async (params) => {
   const result = await instance.get(urlEndpoints.GET_PLANT_NOTIFICATIONS, {
      params,
   })
   return result.data
}

const dismissNotification = async (params) => {
   const result = await instance.delete(urlEndpoints.DISMISS_NOTIFICATION, {
      params,
   })
   return result.data
}
// API call for snooze and mark as read actions.
const upsertUserAction = async (body) => {
   const { status } = await instance.post(
      urlEndpoints.NOTIFICATION_UPSERT_USER_ACTION,
      body
   )
   return { status }
}

const getOperationalAreas = async () => {
   const result = await instance.get(
      urlEndpoints.NOTIFICATION_OPERATIONAL_AREAS
   )
   return result.data
}

const getSchedule = async (data) => {
   const result = await instance.put(urlEndpoints.SCHEDULE, data)
   return result.data
}

const mockECNotification = async (body) => {
   const result = await instance.post(
      urlEndpoints.NOTIFICATION_MOCK_EC_NOTIFICATION,
      body
   )
   return result
}

async function getEquipmentHierarchy(params) {
   const result = await instance.get(urlEndpoints.CC_EQUIMENT_HIERARCHY, {
      params,
   })
   return result.data
}

async function getCustomNotificationDetails(params) {
   const result = await instance.get(urlEndpoints.CUSTOM_NOTIFICATION_DETAILS, {
      params,
   })
   return result.data
}

async function getCustomNotificationSettings(params) {
   const result = await instance.get(
      urlEndpoints.CUSTOM_NOTIFICATION_SETTINGS,
      {
         params,
      }
   )
   return result.data
}

const saveCustomNotification = async (body) => {
   const result = await instance.post(urlEndpoints.SAVE_CUSTOM_NOTIFICATION, body)
   return result.data
}

async function deleteCustomNotification   (params) {
   const result = await instance.delete(urlEndpoints.DELETE_CUSTOM_NOTIFICATION, {
      params,
   })
   return result.data
}

export const notification = {
   getActiveTriggersForPlant,
   getAllRolesForPlant,
   getNotificationTimings,
   updateNotificationTimings,
   getNotificationTriggersList,
   saveTriggersForPlant,
   getAllNotificationsForPlant,
   dismissNotification,
   upsertUserAction,
   getOperationalAreas,
   getSchedule,
   mockECNotification,
   getEquipmentHierarchy,
   getCustomNotificationDetails,
   getCustomNotificationSettings,
   saveCustomNotification,
   deleteCustomNotification,
}
