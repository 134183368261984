import { globalstate$ } from '../smf-ui-util-app'

/**
 * Function to create a websocket connection
 *
 * @param token // Auth token
 * @returns WebSocket connection
 *
 * @author Sridhar Nallasamy
 */
async function getConnection(token) {
   try {
      let newWebSocketConnection = globalstate$.value.websocket
      const newToken = token.replace('Bearer ', '')
      if (newWebSocketConnection == null) {
         newWebSocketConnection = new WebSocket(
            `wss://${process.env.BASE_WS_API_URL}/soc`,
            newToken
         )
         globalstate$.next({
            ...globalstate$.value,
            websocket: newWebSocketConnection,
         })
      }
      return newWebSocketConnection
   } catch (error) {
      console.error('Websocket Error in getConnection: ', error)
      throw new Error(error)
   }
}

/**
 * Function to wait for the websocket connection to be in ready state
 *
 * @param websocket // connection
 * @param counter // initially 10 & get reduced by 1 everytime while going in recursion
 * @param callback // function to send message
 *
 * @author Sridhar Nallasamy
 */
function waitForConnection(websocketConnection, counter, callback) {
   if (counter == 0) {
      globalstate$.next({
         ...globalstate$.value,
         websocketConnectionError: true,
      })
      return
   }
   setTimeout(() => {
      if (websocketConnection.readyState === WebSocket.OPEN) {
         callback()
      } else {
         counter -= 1
         waitForConnection(websocketConnection, counter, callback)
      }
   }, 500)
}

/**
 * Function to send message in websocket connection
 *
 * @param data // message to be sent
 * @return
 *
 * @author Sridhar Nallasamy
 */
async function sendMessage(data) {
   try {
      let ws = await getConnection(data.authorizationToken)
      waitForConnection(ws, 10, () => {
         ws.send(JSON.stringify(data))
         return true
      })
      return
   } catch (error) {
      console.error('Websocket Error in sendMessage: ', error)
      throw new Error(error)
   }
}

/**
 * Function to close the websocket connection
 *
 * @author Sridhar Nallasamy
 */
async function closeConnection() {
   let wsConnection = globalstate$.value.websocket
   wsConnection.close()
   globalstate$.next({ ...globalstate$.value, websocket: null })
}

export const websocket = { getConnection, sendMessage, closeConnection }
