import { instance, urlEndpoints } from './apiHelper'

const getActuals = async (entityId, utility) => {
   const result = await instance.get(urlEndpoints.OEE_ACTUALS, {
      params: { entityId: entityId, utility: utility },
   })
   return result.data
}

const updateActuals = async (body) => {
   const result = await instance.put(urlEndpoints.OEE_ACTUALS, body)
   return result.data
}

const getPresignedExportUrl = async (entityId, utility) => {
   const result = await instance.get(urlEndpoints.OEE_TARGETS_DOWNLOAD, {
      params: { entityId: entityId, utility: utility },
   })
   return result.data
}

const getPresignedUploadUrl = async (params) => {
   const result = await instance.post(urlEndpoints.OEE_TARGETS_UPLOAD, {
      ...params,
   })
   return result.data
}

const validateUpload = async (params) => {
   const result = await instance.post(urlEndpoints.OEE_TARGETS_VALIDATE, {
      ...params,
   })
   return result.data
}

const getTargetsHierarchy = async (entityId) => {
   const result = await instance.get(urlEndpoints.OEE_TARGETS_HIERARCHY, {
      params: { entityId: entityId },
   })
   return result.data
}

const getIdealRunRateHierarchy = async (entityId) => {
   const result = await instance.get(urlEndpoints.OEE_IDEAL_RUN_RATE_HIERARCHY, {
      params: { entityId: entityId },
   })
   return result.data
}

const getTargetsAndIdealRunRateStatus = async (entityId, utility) => {
   const result = await instance.get(
      urlEndpoints.OEE_TARGETS_AND_IDEAL_RUN_RATE_STATUS,
      {
         params: { entityId: entityId, utility: utility },
      }
   )
   return result.data
}

const getIdealPresignedExportUrl = async (entityId) => {
   const result = await instance.get(urlEndpoints.OEE_IDEAL_RUN_RATE_DOWNLOAD, {
      params: { entityId: entityId },
   })
   return result.data
}

const getIdealPresignedUploadUrl = async (params) => {
   const result = await instance.post(urlEndpoints.OEE_IDEAL_RUN_RATE_UPLOAD, {
      ...params,
   })
   return result.data
}

const idealValidateUpload = async (params) => {
   const result = await instance.post(
      urlEndpoints.OEE_IDEAL_RUN_RATE_VALIDATE,
      {
         ...params,
      }
   )
   return result.data
}

const getLossEarnings = async (entityId) => {
   const result = await instance.get(urlEndpoints.OEE_LOSS_EARNINGS, {
      params: { plantId: entityId },
   })
   return result.data
}

const getHeaderDetails = async (entityId) => {
   const result = await instance.get(
      `${urlEndpoints.OEE_HEADER_DATA}/${entityId}`
   )
   return result.data
}

const getOeeHierarchyData = async (entityId) => {
   const result = await instance.get(
      `${urlEndpoints.OEE_HIERARCHY_DATA}?entityId=${entityId}`
   )
   return result.data
}

const getDeviationThresholds = async (entityId) => {
   const result = await instance.get(urlEndpoints.OEE_DEVIATION_THRESHOLDS, {
      params: { entityId: entityId },
   })
   return result.data
}

const updateDeviationThresholds = async (body) => {
   const result = await instance.put(
      urlEndpoints.OEE_DEVIATION_THRESHOLDS,
      body
   )
   return result.data
}

export const oee = {
   getActuals,
   updateActuals,
   getPresignedExportUrl,
   getPresignedUploadUrl,
   validateUpload,
   getIdealPresignedExportUrl,
   getIdealPresignedUploadUrl,
   idealValidateUpload,
   getLossEarnings,
   getTargetsHierarchy,
   getTargetsAndIdealRunRateStatus,
   getHeaderDetails,
   getOeeHierarchyData,
   getDeviationThresholds,
   updateDeviationThresholds,
   getIdealRunRateHierarchy,
}
