import { instance, urlEndpoints } from './apiHelper'

const getDigitalTwinConfig = async (entityId) => {
   const result = await instance.get(urlEndpoints.GET_DIGITAL_TWIN_CONFIG, {
      params: { entityId },
   })
   return result.data
}

const updateDigitalTwinConfig = async (params) => {
   const result = await instance.post(urlEndpoints.POST_DIGITAL_TWIN_CONFIG, {
      ...params,
   })
   return result.data
}

const initiateFileUpload = async (params) => {
   const result = await instance.post(urlEndpoints.DT_INITIATE_FILE_UPLOAD, {
      ...params,
   })
   return result.data
}

const validateFileUpload = async (params) => {
   const result = await instance.post(urlEndpoints.DT_VALIDATE_FILE_UPLOAD, {
      ...params,
   })
   return result.data
}

const saveTags = async (params) => {
   const result = await instance.post(urlEndpoints.UPDATE_DT_TAGS, {
      ...params,
   })
   return result.data
}

const getDigitalTwinTagConfig = async (entityId) => {
   const result = await instance.get(urlEndpoints.GET_DT_TAG_CONFIG, {
      params: { entityId },
   })
   return result.data
}

const getTagConfigSettings = async (entityId) => {
   const result = await instance.get(urlEndpoints.GET_DT_TAG_CONFIG_SETTINGS, {
      params: { entityId },
   })
   return result.data
}

const getTagConfigMetricsSettings = async (params) => {
   const result = await instance.post(
      urlEndpoints.GET_DT_TAG_CONFIG_METRICS_SETTINGS,
      {
         ...params,
      }
   )
   return result.data
}

const updateDigitalTwinTagConfig = async (params) => {
   const result = await instance.post(urlEndpoints.UPDATE_DT_TAG_CONFIG, {
      ...params,
   })
   return result.data
}

const getDigitalTwinUserGuidePdf = async (entityId) => {
   const result = await instance.get(urlEndpoints.GET_DT_USER_GUIDE_PDF, {
      params: { entityId },
   })
   return result.data
}

export const digitalTwin = {
   getDigitalTwinConfig,
   updateDigitalTwinConfig,
   initiateFileUpload,
   validateFileUpload,
   saveTags,
   getDigitalTwinTagConfig,
   getTagConfigSettings,
   getTagConfigMetricsSettings,
   updateDigitalTwinTagConfig,
   getDigitalTwinUserGuidePdf,
}
