import { instance, urlEndpoints } from './apiHelper'

async function getSocData(id) {
   const result = await instance.get(urlEndpoints.SOC_DATA + id)
   return result.data
}

async function getUoMs() {
   const result = await instance.get(urlEndpoints.SOC_UOMS)
   return result.data
}

async function updateRefreshRate(data) {
   const result = await instance.put(urlEndpoints.UPDATE_REFRESH_RATE, data)
   return result.data
}

async function updateRiskCategoryThreshold(data) {
   const result = await instance.put(urlEndpoints.UPDATE_RISK_CATEGORY_THRESHOLD, data)
   return result.data
}

async function updateCriticalityRatings(data) {
   const result = await instance.put(
      urlEndpoints.UPDATE_CRITICALITY_RATINGS,
      data
   )
   return result.data
}

async function downloadCellCriticalityCSV(id) {
   const result = await instance.get(
      urlEndpoints.DOWNLOAD_CELL_CRITICALITY + '?plantId=' + id
   )
   return result.data
}

async function getCriticalityPresignedUrl(params) {
   const result = await instance.post(
      urlEndpoints.GET_CRITICALITY_PRESIGNED_URL,
      params
   )
   return result.data
}

async function postProcessCellCriticalityCsv(params) {
   const result = await instance.post(
      urlEndpoints.POST_CELL_CRITICALITY_CSV_UPLOAD,
      params
   )
   return result.data
}

async function downloadAssetCriticalityCSV(id) {
   const result = await instance.get(
      urlEndpoints.DOWNLOAD_ASSET_CRITICALITY + '?plantId=' + id
   )
   return result.data
}

async function postProcessAssetCriticalityCsv(params) {
   const result = await instance.post(
      urlEndpoints.POST_ASSET_CRITICALITY_CSV_UPLOAD,
      params
   )
   return result.data
}

async function getSocRiskTypes() {
   const result = await instance.get(urlEndpoints.SOC_RISK_TYPE)
   return result.data
}

export const standardOperatingConditions = {
   getSocData,
   getUoMs,
   updateRefreshRate,
   updateCriticalityRatings,
   downloadCellCriticalityCSV,
   getCriticalityPresignedUrl,
   postProcessCellCriticalityCsv,
   downloadAssetCriticalityCSV,
   postProcessAssetCriticalityCsv,
   getSocRiskTypes,
   updateRiskCategoryThreshold,
}
