import { instance, urlEndpoints } from './apiHelper'

async function getDdsPlants() {
   const result = await instance.get(urlEndpoints.FETCH_DDS_PLANTS)
   return result.data
}

async function getDemoList() {
   const result = await instance.get(urlEndpoints.DEMO_LIST)
   return result.data
}

async function createDemo(data) {
   const result = await instance.post(urlEndpoints.CREATE_DEMO, data)
   return result.data
}
async function deleteDemo(demoName) {
   const result = await instance.delete(
      `${urlEndpoints.DELETE_DEMO}?demoName=${demoName}`
   )
   return result.data
}

async function getDemoDetails(demoName) {
   const result = await instance.get(`${urlEndpoints.DEMO_DETAILS}/${demoName}`)
   return result.data
}

const getDDSPlantHierarchy = async (entityId, level) => {
   const result = await instance.get(
      `${urlEndpoints.GET_PLANT_HIERARCHY}?entityId=${entityId}&level=${level}`
   )
   return result.data
}

const getDDSFactoryHealthDeviation = async (entityId, utility) => {
   const encodedEntity = encodeURI(entityId)
   const params = `entityId=${encodedEntity}&utility=${utility}`
   const result = await instance.get(
      `${urlEndpoints.GET_FACTORY_HEALTH_DEVIATION}?${params}`
   )
   return result
}

const getSOCRecipes = async (plantId) => {
   const encodedPlantId = encodeURI(plantId)
   const params = `plantId=${encodedPlantId}`
   const result = await instance.get(
      `${urlEndpoints.GET_SOC_RECIPES}?${params}`
   )
   return result
}

async function getOeeThresholds(entityId) {
   const encodedEntity = encodeURI(entityId)
   const result = await instance.get(
      urlEndpoints.DDS_GET_OEE_THRESHOLD + encodedEntity
   )
   return result.data
}

const saveAllEvents = async (data) => {
   const result = await instance.post(urlEndpoints.SAVE_ALL_EVENTS, data)
   return result.data
}

async function saveAsDemo(data) {
   const result = await instance.post(urlEndpoints.SAVE_AS_DEMO, data)
   return result.data
}

/**
 * API to get details of running demo for user
 * @returns
 */
async function getRunningDemoDetails() {
   const result = await instance.get(urlEndpoints.DEMO_STATE_BY_USER)
   return result.data
}

/**
 * API to notify if demo is paused, played or stopped
 * @param {*} data
 * @returns
 */
async function configureDemoState(data) {
   const result = await instance.put(urlEndpoints.SCHEDULER_EVENT, data)
   return result.data
}
const getTableOfParameters = async (
   entityId,
   recipeId,
   demoName,
   startmin,
   endmin
) => {
   const params = demoName
      ? `entityId=${entityId}&recipeId=${recipeId}&demoName=${demoName}&startmin=${startmin}&endmin=${endmin}`
      : `entityId=${entityId}&recipeId=${recipeId}`
   const result = await instance.get(
      `${urlEndpoints.GET_TABLE_OF_PARAMETERS}?${params}`
   )
   return result
}
const getFaultCodes = async (entityId) => {
   const encodedEntity = encodeURI(entityId)
   const params = `entityId=${encodedEntity}`
   const result = await instance.get(
      `${urlEndpoints.GET_FAULT_CODES}?${params}`
   )
   return result
}

export const dds = {
   getDdsPlants,
   createDemo,
   getDemoList,
   getDemoDetails,
   getDDSPlantHierarchy,
   getDDSFactoryHealthDeviation,
   getSOCRecipes,
   getOeeThresholds,
   getRunningDemoDetails,
   configureDemoState,
   saveAllEvents,
   deleteDemo,
   saveAsDemo,
   getTableOfParameters,
   getFaultCodes,
}
