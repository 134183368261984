import { RBAC_STATUS, RBAC_THRESHOLD_VALUE } from "../constants";

/**
 * @param {Array} groups - Array of {level,permission} roles, from the User details 
 *  returns the Highest priority role status of the given user roles
 */
export function getHighestRBACStatus(groups) { 
    return groups.map(g=>RBAC_STATUS[g]).sort((a,b)=>(b-a))[0] || 110;
}

/**
 * 
 * @param {String} type - Button (in future, we might add some other type of ui elements like modals, etc...)  
 * @param {String} name - Name given to the type (button) of UI element, for identification
 * @param {Number} status - User Status, as per the Role (Level, Permission) he/she has. 
 * @returns Boolean 
 */
export function RBACValidation(type, name, status) {
    const THRESHOLD_VALUE = RBAC_THRESHOLD_VALUE[type][name] || 110;
    return (status >= THRESHOLD_VALUE);
}

/**
 * Method to retrieve the threshold value associated with the user's role
 * @param {String} roleName - Logged in user's role
 * @returns Number 
 */
export function getRoleThresholdValue(roleName) {
    return RBAC_STATUS[roleName] || 110;
}