/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
import { instance, urlEndpoints } from './apiHelper';
import { globalstate$ } from '../smf-ui-util-app'

async function addUpdateBusinessUnit(businessUnitList) {
   const result = await instance.post(
      urlEndpoints.BUSINESS_UNIT,
      businessUnitList
   );
   return result.data;
}

//-- since there is only company per tenant 
//passing a companyId as query param is redundant
async function getAllBusinessUnitList() {
   const result = await instance.get(
      `${urlEndpoints.BUSINESS_UNIT}?companyId=${globalstate$.value.companyData.companyId}`
   );
   return result.data;
}

export const businessUnit = { addUpdateBusinessUnit, getAllBusinessUnitList }
