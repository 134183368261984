import { instance, urlEndpoints } from './apiHelper'

async function fetchDataReportMetaData(params) {
   const result = await instance.get(urlEndpoints.DATA_REPORT_META_DATA, {
      params,
   })
   return result.data
}
async function generateDataReport(data) {
   const result = await instance.post(urlEndpoints.GENERATE_DATA_REPORT, data)
   return result.data
}
async function fetchReportTableData(params) {
   const result = await instance.get(urlEndpoints.FETCH_REPORT_TABLE_DATA, {
      params,
   })
   return result.data
}
async function downloadDataReport(params) {
   const result = await instance.get(urlEndpoints.DOWNLOAD_DATA_REPORT, {
      params,
   })
   return result.data
}

export const dataReports = {
   fetchDataReportMetaData,
   generateDataReport,
   fetchReportTableData,
   downloadDataReport,
}
