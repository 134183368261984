import { instance, urlEndpoints } from './apiHelper'

async function getUserById(userId, params) {
   const result = await instance.get(urlEndpoints.USER + '/' + userId, {
      params,
   })
   return result.data
}

async function getUserDetails(params) {
   const result = await instance.get(urlEndpoints.USER, { params })

   return result.data
}

async function createUser(data) {
   const result = await instance.post(urlEndpoints.USER, data)
   return result.data
}

async function updateUser(userId, data) {
   const result = await instance.put(urlEndpoints.USER + '/' + userId, data)
   return result.data
}

async function deleteUser(userId, data) {
   const result = await instance.delete(urlEndpoints.USER + '/' + userId, data)
   return result.data
}

export const user = {
   getUserDetails,
   getUserById,
   createUser,
   updateUser,
   deleteUser,
}
