import { WEB_SOCKET_CONSTANTS } from '../constants'
import { globalstate$ } from '../smf-ui-util-app'

/**
 * Function to create a websocket connection
 *
 * @param token  Auth token
 * @param channel  channel/topic name
 * @returns WebSocket connection
 *
 * @author Aniket Soni
 */
async function getConnection(token, channel) {
   try {
      let wssConnection = globalstate$.value.wss?.[channel]?.connection
      if (!wssConnection) {
         const newToken = token.replace('Bearer ', '')
         wssConnection = new WebSocket(
            `wss://${process.env.BASE_WS_API_URL}/${channel}`,
            newToken
         )
         globalstate$.next({
            ...globalstate$.value,
            wss: {
               ...globalstate$.value?.wss,
               [channel]: {
                  ...globalstate$.value?.wss?.[channel],
                  connection: wssConnection,
               },
            },
         })
      }
      return wssConnection
   } catch (error) {
      throw new Error(error)
   }
}

/**
 * Function to wait for the websocket connection to be in ready state
 *
 * @param websocket  connection
 * @param counter  initially 10 & get reduced by 1 everytime while going in recursion
 * @param channel  channel/topic name
 * @param callback function to send message
 *
 * @author Aniket Soni
 */
function waitForConnection(websocketConnection, counter, channel, callback) {
   if (counter == 0) {
      globalstate$.next({
         ...globalstate$.value,
         wss: {
            ...globalstate$.value.wss,
            [channel]: {
               ...globalstate$.value.wss[channel],
               errorStatus: true,
               errorMessage: `Something went wrong while connecting to websocket , Please Refresh Page`,
            },
         },
      })
      return
   }
   setTimeout(() => {
      if (websocketConnection.readyState === WebSocket.OPEN) {
         callback()
      } else {
         counter -= 1
         waitForConnection(websocketConnection, counter, channel, callback)
      }
   }, WEB_SOCKET_CONSTANTS.RETRY_DELAY_IN_MILLISECOND)
}

/**
 * Function to send message in websocket connection
 *
 * @param data  message to be sent
 * @return
 *
 * @author Aniket Soni
 */
async function sendMessage(
   data,
   channel = WEB_SOCKET_CONSTANTS.DEFAULT_CHANNEL,
   onConnection = () => {}
) {
   try {
      let wssConnection = await getConnection(data.authorizationToken, channel)
      waitForConnection(
         wssConnection,
         WEB_SOCKET_CONSTANTS.RETRY_TIME,
         channel,
         () => {
            wssConnection.send(JSON.stringify(data))
            onConnection()
            return true
         }
      )
      return
   } catch (error) {
      throw new Error(error)
   }
}

/**
 * Function to close the websocket connection
 *
 * @param channel channel/topic name
 *
 * @author Aniket Soni
 */
async function closeConnection(channel = DEFAULT_CHANNEL) {
   let wssConnection = globalstate$.value.wss?.[channel]?.connection
   wssConnection.close()
   globalstate$.next({
      ...globalstate$.value,
      wss: {
         ...globalstate$.value.wss,
         [channel]: {
            ...globalstate$.value.wss[channel],
            connection: null,
         },
      },
   })
}

export const globalWebsocket = { getConnection, sendMessage, closeConnection }
