import { instance, urlEndpoints } from './apiHelper'

const getActuals = async (entityId, utility) => {
   const result = await instance.get(urlEndpoints.ACTUALS, {
      params: { entityId: entityId, utility: utility },
   })
   return result.data
}

const updateActuals = async (body) => {
   const result = await instance.put(urlEndpoints.ACTUALS, body)
   return result.data
}

const getPresignedExportUrl = async (entityId, utility) => {
   const result = await instance.get(urlEndpoints.TARGETS_DOWNLOAD, {
      params: { entityId: entityId, utility: utility },
   })
   return result.data
}

const getPresignedUploadUrl = async (params) => {
   const result = await instance.post(urlEndpoints.TARGETS_UPLOAD, {
      ...params,
   })
   return result.data
}

const validateUpload = async (params) => {
   const result = await instance.post(urlEndpoints.TARGETS_VALIDATE, {
      ...params,
   })
   return result.data
}

const getTargetsStatus = async (entityId, utility) => {
   const result = await instance.get(urlEndpoints.TARGETS_STATUS, {
      params: { entityId: entityId, utility: utility },
   })
   return result.data
}

const getTargetsValueHierarchy = async (entityId, utility) => {
   const result = await instance.get(urlEndpoints.TARGETS_VALUE_HIERARCHY, {
      params: { entityId: entityId, utility: utility },
   })
   return result.data
}

const getThroughputCurrentStatus = async (entityId) => {
   const result = await instance.get(urlEndpoints.THROUGHPUT_CURRENT_STATUS, {
      params: { entityId: entityId },
   })
   return result.data
}

const getWipCurrentStatus = async (entityId) => {
   const result = await instance.get(urlEndpoints.WIP_CURRENT_STATUS, {
      params: { entityId: entityId },
   })
   return result.data
}

const getDeviationThresholds = async (entityId, utility) => {
   const result = await instance.get(urlEndpoints.DEVIATION_THRESHOLDS, {
      params: { entityId: entityId, utility: utility },
   })
   return result.data
}

const updateDeviationThresholds = async (body) => {
   const result = await instance.put(urlEndpoints.DEVIATION_THRESHOLDS, body)
   return result.data
}

const getHeaderDetails = async (entityId) => {
   const result = await instance.get(
      urlEndpoints.HEADER_DETAILS_CURRENT_STATUS,
      {
         params: { entityId: entityId },
      }
   )
   return result.data
}

const getFactoryConfigStatuses = async (entityId) => {
   const result = await instance.get(urlEndpoints.CONFIG_STATUS, {
      params: { plantId: entityId },
   })
   return result.data
}

export const factoryHealth = {
   getActuals,
   updateActuals,
   getPresignedExportUrl,
   getPresignedUploadUrl,
   validateUpload,
   getTargetsStatus,
   getTargetsValueHierarchy,
   getThroughputCurrentStatus,
   getWipCurrentStatus,
   getDeviationThresholds,
   updateDeviationThresholds,
   getHeaderDetails,
   getFactoryConfigStatuses,
}
