import { instance, urlEndpoints } from './apiHelper'

async function updateSpcConfig(data) {
   const result = await instance.post(urlEndpoints.UPDATE_SPC_CONFIG, data)
   return result.data
}

export const spcConfig = {
   updateSpcConfig,
}
