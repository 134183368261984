import { instance, urlEndpoints } from './apiHelper'

async function updatePMConfig(data) {
   const result = await instance.put(urlEndpoints.UPDATE_PM_CONFIG, data)
   return result.data
}

export const pmConfig = {
   updatePMConfig,
}
