import { instance, urlEndpoints } from './apiHelper'

async function getWidgets(params) {
   const result = await instance.get(urlEndpoints.CC_GET_WIDGETS, { params })
   return result.data
}

async function getWidgetsConfigurations(params) {
   const result = await instance.get(
      urlEndpoints.CC_GET_WIDGET_CONFIGURATIONS,
      { params }
   )
   return result.data
}

async function getEquipmentHierarchy(params) {
   const result = await instance.get(urlEndpoints.CC_EQUIMENT_HIERARCHY, {
      params,
   })
   return result.data
}

async function updateWidgets(data) {
   const result = await instance.post(urlEndpoints.CC_UPDATE_WIDGETS, data)
   return result.data
}

async function getDashboardsInit(params) {
   const result = await instance.get(urlEndpoints.GET_DASHBOARDS_INIT, {
      params,
   })
   return result.data
}

async function createDashboard(data) {
   const result = await instance.post(urlEndpoints.CREATE_DASHBOARD, data)
   return result.data
}

async function getAppSyncConfig() {
   const result = await instance.get(urlEndpoints.CC_APP_SYNC_CONFIG)
   return result.data
}

async function getAllDashboard(params) {
   const result = await instance.get(urlEndpoints.CC_ALL_DASHBOARD, { params })
   return result.data
}

async function deleteDashboard(data) {
   const result = await instance.delete(urlEndpoints.CC_DELETE_DASHBOARD, data)
   return result.data
}

async function getPlantProductionDayAndShiftDetails(params) {
   const result = await instance.get(urlEndpoints.CC_SCHEDULE, { params })
   return result.data
}

async function updateDashboardDetails(data) {
   const result = await instance.put(urlEndpoints.CC_ALL_DASHBOARD, data)
   return result.data
}
async function duplicateDashboard(data) {
   const result = await instance.post(urlEndpoints.CC_DUPLICATE_DASHBOARD, data)
   return result.data
}

export const cc = {
   getWidgets,
   getWidgetsConfigurations,
   getEquipmentHierarchy,
   updateWidgets,
   getAppSyncConfig,
   getDashboardsInit,
   createDashboard,
   getAllDashboard,
   deleteDashboard,
   getPlantProductionDayAndShiftDetails,
   updateDashboardDetails,
   duplicateDashboard,
}
