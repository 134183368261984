import axios from 'axios'
import { instance, urlEndpoints } from './apiHelper'

async function updateGenCIStatus(data) {
   const result = await instance.post(
      urlEndpoints.GET_GENCI_CONFIG_STATUS,
      data
   )
   return result.data
}

async function getSingleDbAutoPromptConfigStatus(factoryId) {
   const result = await instance.get(
      urlEndpoints.GET_SINGLEDB_AUTO_PROMPT_CONFIG_STATUS,
      {
         params: { factoryId: factoryId },
      }
   )
   return result.data
}

async function getVirtualAssistantAutoPromptConfigStatus() {
   const result = await instance.get(
      urlEndpoints.GET_VA_AUTO_PROMPT_CONFIG_STATUS
   )
   return result.data
}

async function getEquipmentHierarchy(params) {
   const result = await instance.get(urlEndpoints.GET_ENTITY_HIERARCHY, {
      params,
   })
   return result.data
}

async function getPreSignedUrlForGenCI(data) {
   const result = await instance.post(
      urlEndpoints.GET_GENCI_PRESIGNED_URL,
      data
   )
   return result.data
}

export async function uploadGenCIConfigFile(presignedUrl, file) {
   return axios({
      url: presignedUrl,
      method: 'put',
      data: file,
      headers: { 'Content-Type': file.type },
   })
}

async function updateGenCIFileConfigInDB(data) {
   const result = await instance.post(
      urlEndpoints.UPDATE_GENCI_FILE_CONFIG,
      data
   )
   return result.data
}

async function getGenCIFileData(factoryId) {
   const result = await instance.get(urlEndpoints.GET_GENCI_FILE_DATA, {
      params: { factoryId },
   })
   return result.data
}

async function getDmsFolders(factoryId) {
   const result = await instance.get(urlEndpoints.GET_DMS_FOLDERS, {
      params: { factoryId },
   })
   return result.data
}

async function saveDmsFolders(data) {
   const result = await instance.post(urlEndpoints.SAVE_DMS_FOLDERS, data)
   return result.data
}

async function getGenCIDataSources(params) {
   const result = await instance.get(urlEndpoints.GENCI_DATA_SOURCES, {
      params: params,
   })
   return result.data
}

async function getLLMFineTuningData(params) {
   const result = await instance.get(urlEndpoints.GENCI_FINE_TUNING, {
      params: params,
   })
   return result.data
}

async function configureLLMFineTuningData(data) {
   const result = await instance.post(urlEndpoints.GENCI_FINE_TUNING, data)
   return result.data
}

async function deleteDMSEmbeddings(data) {
   const result = await instance.delete(
      urlEndpoints.DELETE_DMS_EMBEDDINGS,
      data
   )
   return result.data
}

export const genciConfig = {
   updateGenCIStatus,
   getSingleDbAutoPromptConfigStatus,
   getVirtualAssistantAutoPromptConfigStatus,
   getEquipmentHierarchy,
   getPreSignedUrlForGenCI,
   uploadGenCIConfigFile,
   updateGenCIFileConfigInDB,
   getGenCIFileData,
   getDmsFolders,
   saveDmsFolders,
   getGenCIDataSources,
   getLLMFineTuningData,
   configureLLMFineTuningData,
   deleteDMSEmbeddings,
}
