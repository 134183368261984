import theme from './theme'

const GlobalStyles = () => {
   return {
      '@global': {
         '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
            'scrollbar-width': 'thin',
            '& [class*="MuiPaper-root"][class*="MuiPopover-paper"][class*="MuiPaper-rounded"] ':
               {
                  background: '#171717',
                  color: '#fff',
               },

            '& [class*="MuiPickersCalendarHeader-switchHeader"] ': {
               background: '#232629',
               margin: 0,
               marginTop: '-1px',
            },

            '& [class*="MuiPickersDay-day"] ': {
               color: '#fff',
            },

            '& [class*=MuiPickersCalendarHeader-iconButton] ': {
               color: '#fff',
               background: 'transparent',
            },

            '& [class*=MuiPickersCalendarHeader-daysHeader] ': {
               paddingTop: '12px',
            },

            '& [class*="MuiPickersCalendarHeader-dayLabel"][class*="MuiTypography-caption"] ':
               {
                  color: '#d8d8d8',
               },

            '& [class*="MuiPickersDay-day"][class*="MuiPickersDay-daySelected:hover"] ':
               {
                  background: '#95ca3c',
               },

            '& [class*="MuiButtonBase-root"][class*="MuiIconButton-root"][class*="MuiPickersDay-day"][class*="MuiPickersDay-daySelected"]':
               {
                  backgroundColor: '#95ca3c',
                  color: 'black !important',
               },

            '& [class*="MuiPickersDay-day:hover"] ': {
               background: '#232629',
            },

            '& [class*="MuiButtonBase-root"][class*="MuiIconButton-root"][class*="MuiPickersDay-day"][class*="MuiPickersDay-current"] ':
               {
                  color: "#95ca3c' !important",
               },

            '& [class*="MuiButtonBase-root"][class*="MuiIconButton-root"][class*="MuiPickersDay-day"][class*="MuiPickersDay-dayDisabled"]':
               {
                  color: 'grey',
               },

            '& [class*="MuiInputBase-input"][class*="MuiInput-input"][class*="MuiInputBase-inputAdornedEnd"]':
               {
                  padding: '0.3em 0.5em',
               },

            '& [class*="MuiPickersToolbar-toolbar"] ': {
               backgroundColor: '#232629 !important',
               height: '4.5em !important',
            },

            '& [class*="MuiPickersDay-current "] ': {
               color: 'unset !important',
            },

            '& [class*="MuiTypography-root"][class*="MuiPickersToolbarText-toolbarTxt"][class*="MuiPickersToolbarText-toolbarBtnSelected"][class*="MuiTypography-h4"][class*="MuiTypography-alignCenter"]':
               {
                  fontWeight: '700',
               },

            '& [class*="-MuiButtonBase-root"][class*="MuiButton-root"][class*="MuiButton-text"][class*="MuiPickersToolbarButton-toolbarBtn"] ':
               {
                  paddingBottom: '0.5em',
               },

            '& [class*="MuiTypography-root"][class*="MuiPickersToolbarText-toolbarTxt"][class*="MuiPickersToolbarText-toolbarBtnSelected"][class*="MuiTypography-subtitle1"]':
               {
                  fontWeight: '700',
               },

            '& [class*="MuiTypography-root"][class*="MuiPickersYear-root"][class*="MuiPickersYear-yearDisabled"][class*="MuiTypography-subtitle1"]':
               {
                  color: 'grey',
               },

            '& [class*="MuiTypography-root"][class*="MuiPickersYear-root"][class*="MuiPickersYear-yearSelected"][class*="MuiTypography-h5"][class*="MuiTypography-colorPrimary"]':
               {
                  color: '#95ca3c !important',
               },
            '& .react-autosuggest__suggestions-list': {
               maxHeight: '300px',
               overflow: 'auto',
            },
         },
         html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%',
            fontFamily: 'Open Sans',
            color: '#fff',
         },
         body: {
            backgroundColor: '#171717',
            height: '100%',
            width: '100%',
         },
         a: {
            textDecoration: 'none',
         },
         '#root': {
            height: '100vh',
            width: '100%',
         },
         '*::-webkit-scrollbar': {
            width: '0.375em',
            height: '0.4375em',
         },
         '*::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.background.darkGrey,
            borderRadius: 4,
         },
      },
   }
}

export default GlobalStyles
