import { SplitSdk } from '@splitsoftware/splitio-react'

// Central Split Factory with initial key as 'anonymous'
export const splitRootFactory = SplitSdk({
   core: {
      authorizationKey: process.env.SPLIT_CLIENT_KEY,
      key: 'anonymous',
   },
   streamingEnabled: true,
})
