import { instance, urlEndpoints } from './apiHelper'

async function getWatchLists(params) {
   const result = await instance.get(urlEndpoints.WATCHLIST, { params })
   return result.data
}

async function getFilterValues(params) {
    const result = await instance.get(urlEndpoints.FILTER_VALUES, { params })
    return result.data
}

async function getOperatingConditions(data) {
    const result = await instance.post(urlEndpoints.OPERATING_CONDITION, data)
    return result.data
}

export const qualityManagement = { getWatchLists , getFilterValues, getOperatingConditions}
