import { instance, urlEndpoints } from './apiHelper'

async function getMDUSourceData(params) {
   const result = await instance.get(urlEndpoints.GET_MDU_SOURCE_DATA, {
      params,
   })
   return result.data
}

async function getMDUGatewayTableData(params) {
   const result = await instance.get(urlEndpoints.GET_MDU_GATEWAY_TABLE_DATA, {
      params,
   })
   return result.data
}

async function postEditMDUGatewayData(params) {
   const result = await instance.post(urlEndpoints.POST_GATEWAY_EDIT_DATA, params)
   return result.data
}

async function postMDUGatewayStatusData(params) {
   const result = await instance.post(urlEndpoints.POST_GATEWAY_ENABLE_DISABLE_DATA, params)
   return result.data
}

export const mduAPIs = {
   getMDUSourceData,
   getMDUGatewayTableData,
   postEditMDUGatewayData,
   postMDUGatewayStatusData,
}
