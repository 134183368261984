import { instance, urlEndpoints } from './apiHelper'

const getPPEInsightsConfig = async (plantId) => {
   const result = await instance.get(urlEndpoints.GET_PPE_INSIGHTS_CONFIG, {
      params: { plantId },
   })
   return result.data
}

const updatePPEInsightsConfig = async (params) => {
   const result = await instance.post(urlEndpoints.POST_PPE_INSIGHTS_CONFIG, {
      ...params,
   })
   return result.data
}

export const ppeInsights = {
    getPPEInsightsConfig,
   updatePPEInsightsConfig,
}
