import { instance, urlEndpoints } from './apiHelper'

const getBiTools = async () => {
   const result = await instance.get(urlEndpoints.BIC_GET_BI_TOOLS)
   return result.data
}

const getConfiguredTool = async (plantId) => {
   const result = await instance.get(urlEndpoints.BIC_GET_CONFIGURED_TOOL, {
      params: { plantId },
   })
   return result.data
}

const updateConfiguredTool = async (params) => {
   const result = await instance.post(urlEndpoints.BIC_POST_CONFIGURED_TOOL, {
      ...params,
   })
   return result.data
}

const deleteConfiguredTool = async (plantId) => {
   const result = await instance.delete(
      urlEndpoints.BIC_DELETE_CONFIGURED_TOOL,
      { data: { plantId } }
   )
   return result.data
}

export const biConfig = {
   getBiTools,
   getConfiguredTool,
   updateConfiguredTool,
   deleteConfiguredTool,
}
