import { instance, urlEndpoints } from './apiHelper'

async function getRoleById(roleId) {
   const result = await instance.get(urlEndpoints.ROLE_CREATE + '/' + roleId)
   return result.data
}

async function getRoleByName(roleName) {
   const result = await instance.get(`${urlEndpoints.ROLE_NAME}${roleName}`)
   return result.data
}

async function getRoleByLevel(params) {
   const result = await instance.get(urlEndpoints.LEVEL_ROLE, { params } )
   return result.data
}

async function getRoleDropDown(params) {
   const result = await instance.get(urlEndpoints.DROP_DOWN, { params } )
   return result.data
}
async function getRoleDetails(params) {
   const result = await instance.get(urlEndpoints.ROLES, { params })
   return result.data
}
async function createRole(data) {
   const result = await instance.post(urlEndpoints.ROLE_CREATE, data)
   return result.data
}
async function updateRole(data) {
   const result = await instance.put(urlEndpoints.ROLE_CREATE, data)
   return result.data
}
async function deleteRole(roleId) {
   const result = await instance.delete(urlEndpoints.ROLE_CREATE + '/' + roleId)
   return result.data
}
async function getRolesDropdown(data) {
   const result = await instance.get(urlEndpoints.ROLES, data)
   return result.data
}
export const role = {
   getRoleById,
   getRoleByName,
   getRoleByLevel,
   getRoleDetails,
   createRole,
   getRoleDropDown,
   getRolesDropdown,
   updateRole,
   deleteRole,
}
