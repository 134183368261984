export const URL = {
   NOT_FOUND: '/404',
   OTHER: '*',
   GLOBAL: '/',
   DAY_TO_DAY: '/d2d',
   PERFORMANCE_MANAGEMENT: '/performance_management',
   CBM: '/cbm',
   ORDER_MANAGEMENT: '/order_management',

   ACCESS: '/access',
   CONFIG: '/config',
   GENERAL_CONFIG: '/general_config',
   ACCESS_CONFIG: '/accessconfig',
   SOC_CONFIG: '/standard_operating_conditions',

   OEE: '/oee',
}
