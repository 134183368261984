import { instance, urlEndpoints } from './apiHelper'

async function getConfigStatusData(params) {
   const result = await instance.get(
      urlEndpoints.GET_CONSTRAINTS_CONFIG_STATUS,
      { params }
   )
   return result.data
}

async function getUoM() {
   const result = await instance.get(urlEndpoints.GET_CONSTRAINTS_CONFIG_UOM)
   return result.data
}

async function getTimeRange() {
   const result = await instance.get(
      urlEndpoints.GET_CONSTRAINTS_CONFIG_TIME_RANGE
   )
   return result.data
}

async function getActualsData(params) {
   const result = await instance.get(
      urlEndpoints.GET_CONSTRAINTS_CONFIG_ACTUALS,
      {
         params,
      }
   )
   return result.data
}

async function getTargetData(params) {
   const result = await instance.get(
      urlEndpoints.GET_CONSTRAINTS_CONFIG_TARGETS,
      {
         params,
         headers: { 'Cache-Control': 'no-cache, no-store' },
      }
   )
   return result.data
}

async function getMiscData(params) {
   const result = await instance.get(urlEndpoints.GET_CONSTRAINTS_CONFIG_MISC, {
      params,
      headers: { 'Cache-Control': 'no-cache, no-store' },
   })
   return result.data
}

async function postActualsData(data) {
   const result = await instance.post(
      urlEndpoints.POST_CONSTRAINTS_CONFIG_ACTUALS,
      data
   )
   return result.data
}

async function postTargetData(data) {
   const result = await instance.post(
      urlEndpoints.POST_CONSTRAINTS_CONFIG_TARGETS,
      data
   )
   return result.data
}

async function postMiscData(data) {
   const result = await instance.post(
      urlEndpoints.POST_CONSTRAINTS_CONFIG_MISC,
      data
   )
   return result.data
}

async function getFaultCodeFile(params) {
   const result = await instance.get(
      urlEndpoints.GET_FAULT_CODE_FILE_DOWNLOAD,
      {
         params,
      }
   )
   return result.data
}
async function postUploadPresignedURL(data) {
   const result = await instance.post(
      urlEndpoints.POST_UPLOAD_PRESIGNED_URL,
      data
   )
   return result.data
}

async function getUploadInfo(params) {
   const result = await instance.get(urlEndpoints.GET_UPLOAD_INFO, { params })
   return result.data
}

// ** NOTE: keep the constraints d2d apis below this line.

async function getContraintStatusList(params) {
   const result = await instance.get(urlEndpoints.GET_CONSTRAINTS_STATUS_LIST, {
      params,
   })
   return result.data
}

async function getCardContraintsData(params) {
   const result = await instance.get(urlEndpoints.GET_CARD_CONSTRAINTS_DATA, {
      params,
   })
   return result.data
}

async function getConstraintsHeaderData(params) {
   const result = await instance.get(
      urlEndpoints.GET_CONSTRAINTS_D2D_HEADER_DATA,
      {
         params,
      }
   )
   return result.data
}

async function getViewEquipmentFaultData(params) {
   const result = await instance.get(
      urlEndpoints.GET_VIEW_EQUIPMENT_FAULT_DATA,
      {
         params,
      }
   )
   return result.data
}

async function getConstraintData(params) {
   const result = await instance.get(urlEndpoints.GET_CONSTRAINTS_TABLE_DATA, {
      params,
   })
   return result.data
}

async function postSnoozeConstraintData(data) {
   const result = await instance.post(
      urlEndpoints.POST_SNOOZE_CONSTRAINTS_DATA,
      data
   )
   return result.data
}

async function postReopenSnoozeConstraintData(data) {
   const result = await instance.post(
      urlEndpoints.POST_REOPEN_SNOOZE_CONSTRAINTS_DATA,
      data
   )
   return result.data
}

async function getSnoozeTimeData(params) {
   const result = await instance.get(
      urlEndpoints.GET_CONSTRAINTS_CONFIG_SNOOZE_TIME,
      {
         params,
      }
   )
   return result.data
}
async function getNotesData(params) {
   const result = await instance.get(urlEndpoints.GET_NOTES_DATA, {
      params,
   })
   return result.data
}

async function postNotesData(data) {
   const result = await instance.post(urlEndpoints.POST_NOTES_DATA, data)
   return result.data
}

// WO apis
async function getWorkOrderListData(params) {
   const result = await instance.get(urlEndpoints.GET_WORK_ORDER_LIST_DATA, {
      params,
   })
   return result.data
}

async function getWorkOrderDetailsData(params) {
   const result = await instance.get(urlEndpoints.GET_WORK_ORDER_DETAILS_DATA, {
      params,
   })
   return result.data
}

async function getWorkOrderStatus(params) {
   const result = await instance.get(urlEndpoints.GET_WORK_ORDER_STATUS, {
      params,
   })
   return result.data
}

async function postCreateWorkOrder(data) {
   const result = await instance.post(urlEndpoints.POST_CREATE_WORK_ORDER, data)
   return result.data
}

export const equipmentConstraintsConfigurationAPIs = {
   getConfigStatusData,
   getUoM,
   getTimeRange,
   getActualsData,
   getTargetData,
   getMiscData,
   postTargetData,
   postMiscData,
   postActualsData,
   getFaultCodeFile,
   postUploadPresignedURL,
   getUploadInfo,
   getContraintStatusList,
   getCardContraintsData,
   getConstraintsHeaderData,
   getViewEquipmentFaultData,
   getConstraintData,
   postSnoozeConstraintData,
   postReopenSnoozeConstraintData,
   getSnoozeTimeData,
   getNotesData,
   postNotesData,
   getWorkOrderListData,
   getWorkOrderDetailsData,
   getWorkOrderStatus,
   postCreateWorkOrder,
}
