import { instance, urlEndpoints } from './apiHelper'

async function getRecipes(plantId) {
   const result = await instance.get(urlEndpoints.RECIPE, {
      params: { plantId: plantId },
   })
   return result.data
}

async function updateRecipes(data) {
   const result = await instance.put(urlEndpoints.RECIPE, data)
   return result.data
}

async function deleteRecipe(recipeId) {
   const result = await instance.delete(urlEndpoints.RECIPE + '/' + recipeId)
   return result.data
}

async function getSocRecipeRowsLimit(id) {
   const result = await instance.get(
      urlEndpoints.SOC_RECIPE_LIMIT + '?plantId=' + id
   )
   return result.data
}

export const recipe = {
   getRecipes,
   updateRecipes,
   deleteRecipe,
   getSocRecipeRowsLimit,
}
