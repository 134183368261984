/* eslint-disable prettier/prettier */
// Global State Contract

export const intialGlobalState = {
   showOldUI: false,
   isLoggedInUser: false,
   sessionToken: '',
   idToken: '',
   factoryId: null,
   plantId: '',
   plantName: '',
   plantRole: '',
   stepperCurrentState: null,
   stepperIsEditing: false,
   generalConfigTotalSteps: 6,
   constantsAPI: {},
   context: null,
   menuDisabledState: true,
   userInformation: null,
   businessUnit: '',
   generalConfigSentinel: 0,
   isLoggedOut: false,
   socketPlantId: '',
   configData: [],
   factoryConfigStatuses: [
      {
         configName: 'factoryHealth',
         isCompleted: false, //Status Flag which represent whether all the steps of Factory Health configuration is completed or not at a plant level
      },
   ],
   configStatus: {
      factoryHealth: false,
      factoryGeneral: false,
      soc: false,
      constraints: false,
      oee: false,
      connectivity: false,
      pm: false,
      sensorBasedPdm: false,
      spc: false,
      yp: false,
      genci: false,
   },
   companyData: {
      companyName: '',
   },
   websocket: null,
   websocketConnectionError: false,
   wss: null, // globalWebsocket
   recipeUpload: {},
   isFGCComplete: false, // Boolean flag to store the status of completion of all 6 steps of Factory General Configuration at plant level
   factoryGeneralEditState: {
      isUnsavedChange: false,
      action: { isUserNavigating: false, location: '' },
   },
   socEditState: {
      isUnsavedRecipe: false,
      action: { isUserNavigating: false, location: '' },
   },
   isEIPorUoMChanged: false,
   workOrdersState: {
      isWOPageOpen: false,
      entityID: '',
      faultCode: null,
   },
   globeDrawerState: {
      mapViewTableInitSortBU: {
         by: 'businessUnit',
         order: 'asc',
         correspondingChild: 'entityName',
      },
      mapViewTableInitSort: { by: 'entityName', order: 'asc' },
   },
   ddsDemoStatus: {},
   performaneManagementSelectedTab: 0,
   ddsLogoutConfig: {
      isLogoutBtnClicked: false,
      isConfirmLogoutClicked: false,
      areAnyUnsavedChanges: false,
   },
   ddsNavigateAwayConfig: {
      isUnsavedChange: false,
      action: { isUserNavigating: false, location: '' },
   },
}
