import { instance, urlEndpoints } from './apiHelper'

async function updateYpConfig(data) {
   const result = await instance.post(urlEndpoints.UPDATE_YP_CONFIG, data)
   return result.data
}

export const ypConfig = {
   updateYpConfig,
}
