import {
   unstable_createMuiStrictModeTheme as createMuiTheme,
   colors,
} from '@material-ui/core'

import typography from './typography'

const customColors = {
   tooltipGrey: '#505050',
   lightGrayishBlue: '#F4F6F8',
   black: '#000000',
   wrapperGrey: '#40444a',
   white: '#FFFFFF',
   greyishWhite: '#EFEEEE',
   darkGrey: '#53565A',
   darkShallowGrey: '#53565a4d',
   darkReddishGrey: '#444444',
   darkishBlackBlue: '#232629',
   cfoGreen: '#CCFF00',
   dullDark: '#1F2226',
   selectedBgGreen: '#26890D',
   configLighterDark: '#171717',
   defaultStepperBackground: '#1C1C1C',
   lightWhiteGrey: '#97999B',
   lavaRed: '#DA291C',
   blackGrey: '#202020',
   layoutBackgroundColor: '#171717',
   darkGreen: '#26890D',
   slimyGreen: '#26890D',
   saveGreen: '#86BC25',
   darkGrayishBlue: '#2F343B',
   lightRaven: '#75787B',
   lightYellowishGray: ' #D0D0CE',
   defaultStepperBorder: '#888888',
   selectedGreen: '#adea3f',
   headerBlack: '#1D1E20',
   blockGrey: '#808080',
   targetLightGrey: '#53565A',
   headerTitleBackgroudColor: '#131313',
   headerBackgroundColor: '#1f2226',
   dividerGrey: '#373737',
   highlightBlue: '#00A3E0',
   progressBarBlue: '#00A3E0',
   progressBarCyan: '#3EFAC5',
   progressLabelPurple: '#5A5A89',
   progressLabelGrey: '#AFAFAF',
   progressBarEmpty: '#403838',
   wipTargetPurple: '#53556A',
   legendPurple: '#9f42cb',
   legendBlue: '#1e70eb',
   lightBlue: '#4EB9F8',
   elevationGrey: '#242323',
   nobel: '#979797',
   greyishBlue: '#97989A',
   mediumLightCyanBlue: '#A7A8AA',
   childLightBlack: '#121212',
   lightUnitGrey: '#BFBFBF',
   tangerineColor: '#ED8B00',
   reddishGrey: '#0B0A0A',
   exploreBlue: '#A0DCFF',
   reddishWhite: '#D8D8D8',
   balticSea: '#2B2929',
   eerieBlack: '#181818',
   exploreHeaderTextGrey: '#D8D8D8',
   plantGreen: '#adea3f',
   plantAmber: '#ED8B00',
   plantTableRowGray: '#2e2e2e',
   plantTableInnerRowGray: '#474747',
   deleteAlertLightBlue: '#A0DCFF',
   CBMTabWhite: '#FEFEFE',
   CBMTabSelectedBlack: '#35353B',
   darkCharcolGrey: '#363636',
   duneGrey: '#353535',
   twentyThreeJungleGrey: '#232323',
   twentySevenJungleGrey: '#272727',
   nero: '#222222',
   spanishGrey: '#979797',
   statusBoxBackgroundGrey: '#2F2F2F',
   shuttleGray: '#63666A',
   blackOliveGreen: '#20361B',
   nileBlue: '#104153',
   bronzeTone: '#573A10',
   cocoaToneRed: '#511C18',
   popupGrey: '#BABABA',
   graniteGray: '#5E5E5E',
   weatheredStone: '#C4C4C4',
   primrose: '#E3E48D',
   pantone: '#6FC2B4',
   brightPink: '#FF449E',
   metalGrey: '#2D3338',
   blackishGrey: '#232629',
   green: '#43B02A',
   darkAquaBlue: '#007CB0',
   headerDividerGrey: 'rgba(255, 255, 255, 0.1)',
   popoverGrey: 'rgba(83, 86, 90, 0.2)',
   popoverBoxShadow: 'rgba(0, 0, 0, 0.4)',
   hierarchyTableGrey: 'rgba(83, 86, 90, 0.3)',
   footerGrey: 'rgba(35, 38, 41, 0.5)',
   pantoneCoolGrey: '#D0D0CE',
   mediumLightShadeCyanBlue: '#62B5E5',
   modalBackdrop: 'rgba(0,0,0,0.8)',
   tooltipShadow: 'rgba(0, 0, 0, 0.5)',
}

export const globeColors = {
   darkBlack: '#010c14',
   paleWhite: '#E9FAFF',
   haloYellow: 'yellow',
   white: '#fff',
   imageBackground: '#0D203A',
   markerRed: '#DA291C',
   markerOrange: '#FFCD00',
   markerGreen: '#44B02A',
}

export const watchListColors = {
   nobelGrey: '#979797',
   white: '#fff',
   markerRed: '#DA291C',
   markerOrange: '#ED8B00',
   markerYellow: '#FFCD00',
   markerGreen: '#95CA3C',
   markerBlue: '#33F0FF',
   markerSkyBlue: '#62B5E5',
   saveGreen: customColors.saveGreen,
   markerGray: '#BBBCBC',
}

export const SwitchColors = {
   saveGreen: customColors.saveGreen,
   mediumLightCyanBlue: customColors.mediumLightCyanBlue,
   blackGrey: customColors.blackGrey,
}

export const globeFontStyles = {
   heavyFont: '600',
   lightFont: '300',
}
export const performanceColors = {
   alertBlue: '#00A3E0',
}
export const performanceFontStyles = {
   normalFont: '400',
   heavyFont: '700',
   heavierFont: '900',
}
const theme = createMuiTheme({
   overrides: {
      MuiSnackbar: { root: { zIndex: 1000 } },
      MuiMenuItem: {
         // For ListItem, change this to MuiListItem
         root: {
            padding: '1em',
            '&$selected': {
               backgroundColor: customColors.darkGrayishBlue,
            },
            '&:hover': {
               backgroundColor: customColors.darkGrayishBlue,
            },
         },
      },
      // For Scheduler calendar
      Cell: {
         dayOfWeek: {
            color: customColors.white,
            paddingBottom: '1em',
            fontSize: '1em',
         },
         otherMonth: {
            color: `rgba(255, 255, 255, 0.5)`,
         },
      },
      MuiSvgIcon: {
         root: {
            color: '#fff',
         },
      },
      MuiStepLabel: {
         root: {
            flexDirection: 'row-reverse',
         },
         completed: {
            color: `${customColors.saveGreen} !important`,
         },
         active: {
            color: `${customColors.saveGreen} !important`,
         },
         label: {
            color: `${customColors.lightWhiteGrey} !important`,
         },
         iconContainer: {
            margin: '0 0 0 1em',
         },
      },
      MuiStepConnector: {
         root: {
            width: '0.5em',
            height: '0.5em',
            margin: '0 0.5em',
            borderRadius: '50%',
            background: customColors.darkGrey,
            flex: 'inherit',
         },
         line: {
            display: 'none',
         },
      },
      MuiStepper: {
         root: {},
         active: {},
         horizontal: {
            background: customColors.configLighterDark,
            padding: '1em 0.5em 1em 0',
            overflowX: 'auto',
         },
      },
      MuiStep: {
         root: {
            background: customColors.defaultStepperBackground,
            borderRadius: 4,
            color: customColors.white,
            padding: '1em',
         },
         horizontal: {
            width: '14em',
         },
      },
      // For overiding the material UI's stepper component
      MuiStepIcon: {
         root: {
            display: 'none',
            '&$active': {
               display: 'block',
               color: customColors.saveGreen,
            },
            '&$completed': { display: 'block', color: customColors.saveGreen },
         },
         active: {},
         text: { fill: customColors.black },
         completed: {},
      },
      MuiAutocomplete: {
         inputRoot: {
            '&&[class*="MuiOutlinedInput-root"] $input': {
               padding: 1,
            },
         },
      },
      MuiDrawer: {
         paperAnchorDockedLeft: {
            borderRight: 0,
         },
      },
      MuiListItemIcon: {
         root: {
            minWidth: 0,
            minHeight: 0,
         },
      },
   },
   customColors: {
      lightGrayishBlue: customColors.lightGrayishBlue,
      darkReddishGrey: customColors.darkReddishGrey,
      greyishBlue: customColors.greyishBlue,
      black: customColors.black,
      white: customColors.white,
      greyishWhite: customColors.greyishWhite,
      darkGrey: customColors.darkGrey,
      darkShallowGrey: customColors.darkShallowGrey,
      darkishBlackBlue: customColors.darkishBlackBlue,
      dullDark: customColors.dullDark,
      slimyGreen: customColors.slimyGreen,
      defaultStepperBackground: customColors.defaultStepperBackground,
      saveGreen: customColors.saveGreen,
      headerBlack: customColors.headerBlack,
      blockGrey: customColors.blockGrey,
      targetLightGrey: customColors.targetLightGrey,
      progressBarBlue: customColors.progressBarBlue,
      progressBarCyan: customColors.progressBarCyan,
      progressLabelPurple: customColors.progressLabelPurple,
      progressBarEmpty: customColors.progressBarEmpty,
      progressLabelGrey: customColors.progressLabelGrey,
      wipTargetPurple: customColors.wipTargetPurple,
      legendPurple: customColors.legendPurple,
      legendBlue: customColors.legendBlue,
      blackGrey: customColors.blackGrey,
      wrapperGrey: customColors.wrapperGrey,
      headerTitleBackgroudColor: customColors.headerTitleBackgroudColor,
      headerBackgroundColor: customColors.headerBackgroundColor,
      dividerGrey: customColors.dividerGrey,
      highlightBlue: customColors.highlightBlue,
      childLightBlack: customColors.childLightBlack,
      lightUnitGrey: customColors.lightUnitGrey,
      reddishGrey: customColors.reddishGrey,
      exploreBlue: customColors.exploreBlue,
      reddishWhite: customColors.reddishWhite,
      eerieBlack: customColors.eerieBlack,
      plantGreen: customColors.plantGreen,
      plantAmber: customColors.plantAmber,
      plantTableRowGray: customColors.plantTableRowGray,
      plantTableInnerRowGray: customColors.plantTableInnerRowGray,
      exploreHeaderTextGrey: customColors.exploreHeaderTextGrey,
      deleteAlertLightBlue: customColors.deleteAlertLightBlue,
      CBMTabWhite: customColors.CBMTabWhite,
      CBMTabSelectedBlack: customColors.CBMTabSelectedBlack,
      nero: customColors.nero,
      nobelGrey: customColors.nobel,
      statusBoxBackgroundGrey: customColors.statusBoxBackgroundGrey,
      tooltipGrey: customColors.tooltipGrey,
      twentySevenJungleGrey: customColors.twentySevenJungleGrey,
      popupGrey: customColors.popupGrey,
      brightPink: customColors.brightPink,
      blackishGrey: customColors.blackishGrey,
      metalGrey: customColors.metalGrey,
      green: customColors.green,
      headerDividerGrey: customColors.headerDividerGrey,
      popoverGrey: customColors.popoverGrey,
      popoverBoxShadow: customColors.popoverBoxShadow,
      hierarchyTableGrey: customColors.hierarchyTableGrey,
      footerGrey: customColors.footerGrey,
      modalBackdrop: customColors.modalBackdrop,
      tooltipShadow: customColors.tooltipShadow,
      darkAquaBlue: customColors.darkAquaBlue,
   },
   palette: {
      background: {
         dark: customColors.lightGrayishBlue,
         default: colors.common.white,
         paper: colors.common.black,
         blackGrey: customColors.blackGrey,
         dullDark: customColors.dullDark,
         darkGreen: customColors.darkGreen,
         layoutBackgroundColor: customColors.layoutBackgroundColor,
         configLighterDark: customColors.configLighterDark,
         darkishBlackBlue: customColors.darkishBlackBlue,
         saveGreen: customColors.saveGreen,
         darkGrey: customColors.darkGrey,
         elevationGrey: customColors.elevationGrey,
         errorColor: customColors.lavaRed,
         infoColor: customColors.highlightBlue,
         successColor: customColors.saveGreen,
         tangerineColor: customColors.tangerineColor,
         darkCharcolGrey: customColors.darkCharcolGrey,
         blackerRangoonGreen: customColors.defaultStepperBackground,
         twentyThreeJungleGrey: customColors.twentyThreeJungleGrey,
         twentySevenJungleGrey: customColors.twentySevenJungleGrey,
         eerieBlack: customColors.eerieBlack,
         shuttleGray: customColors.shuttleGray,
         wrapperGrey: customColors.wrapperGrey,
         cocoaToneRed: customColors.cocoaToneRed,
         bronzeTone: customColors.bronzeTone,
         blackOliveGreen: customColors.blackOliveGreen,
         nileBlue: customColors.nileBlue,
         graniteGray: customColors.graniteGray,
         weatheredStone: customColors.weatheredStone,
         headerBlack: customColors.headerBlack,
         primrose: customColors.primrose,
         pantone: customColors.pantone,
         gunmetalGrey: customColors.darkGrayishBlue,
         metalGrey: customColors.metalGrey,
         footerGrey: customColors.footerGrey,
         mediumLightCyanBlue: customColors.mediumLightCyanBlue,
      },
      primary: {
         main: colors.indigo[500],
      },
      secondary: {
         main: colors.indigo[500],
      },
      text: {
         primary: colors.common.white,
         secondary: colors.common.black,
         greenText: customColors.cfoGreen,
         errorText: customColors.lavaRed,
         lightYellowishGray: customColors.lightYellowishGray,
         darkGrey: customColors.darkGrey,
         lightBlue: customColors.lightBlue,
         nobel: customColors.nobel,
         mediumLightCyanBlue: customColors.mediumLightCyanBlue,
         blockGrey: customColors.blockGrey,
         infoColor: customColors.highlightBlue,
         spanishGrey: customColors.spanishGrey,
         saveGreen: customColors.saveGreen,
         lightUnitGrey: customColors.lightUnitGrey,
         configLighterDark: customColors.configLighterDark,
         shuttleGray: customColors.shuttleGray,
         lightWhiteGrey: customColors.lightWhiteGrey,
         darkAquaBlue: customColors.darkAquaBlue,
      },
      border: {
         primary: colors.common.white,
         secondary: colors.common.black,
         lightWhiteGrey: customColors.lightWhiteGrey,
         darkGrey: customColors.darkGrey,
         lightRaven: customColors.lightRaven,
         saveGreen: customColors.saveGreen,
         highlightBlue: customColors.highlightBlue,
         balticSea: customColors.balticSea,
         tangerineColor: customColors.tangerineColor,
         lavaRed: customColors.lavaRed,
         duneGrey: customColors.duneGrey,
         popoverGrey: customColors.popoverGrey,
         hierarchyTableGrey: customColors.hierarchyTableGrey,
         wrapperGrey: customColors.wrapperGrey,
      },
      divider: customColors.greyishBlue,
      selected: {
         greenSelect: customColors.cfoGreen,
         selectedGreenBg: customColors.selectedBgGreen,
         darkGrayishBlue: customColors.darkGrayishBlue,
         configSidebarGreen: customColors.saveGreen,
         configSidebarWhite: customColors.white,
      },
   },

   typography,
})

export default theme
