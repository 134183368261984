export const AUTHORIZATION_TOKEN = 'authToken'
export const ACCESS_TOKEN_KEY = 'access_token'
export const BEARER_TOKEN_PREFIX = 'Bearer '
export const ID_TOKEN_KEY = 'id_token'
export const REFRESH_TOKEN_KEY = 'refresh_token'
export const EXPIRE_TIME = 'expireTime'
export const AUTH_CODE = 'authorization_code'
export const EXTEND_LOGIN = 'isExtendedLogin'
export const ID_TOKEN = 'idToken'
export const REDIRECT_FROM_NEW_TO_OLD = 'config_ui'

// RBAC - Constants
export const RBAC_CONSTANTS = {
   TEMPORARY_EMAIL_ID: 'harshraj@deloitte.com',
   LOGOUT: 'Logout',
   LANDING_PAGES: {
      GLOBE: 'Globe',
      PERFORMANCE_MANAGEMENT: 'Performance Management',
      ASSET_MANAGEMENT: 'Asset Management',
      ORDER_MANAGEMENT: 'Order Management',
   },
   LEVELS: {
      PLANT: 'Plant',
      BUSINESS_UNIT: 'Business Unit',
      GLOBAL: 'Global',
   },
   PERMISSIONS: {
      // Arrange in heirachy
      VIEW_ONLY: 'View Only',
      EDIT_ONLY: 'Edit Only',
      EDIT_AND_CONFIGURE: 'Edit and Configure',
      SYSTEM_ADMIN: 'System Admin',
   },

   ADMIN_ROLES: {
      PLANT: 'PlantSystemAdmin',
      BUSINESS_UNIT: 'BusinessUnitSystemAdmin',
      GLOBAL: 'GlobalSystemAdmin',
   },
}

export const RBAC_STATUS = {
   // View Only - for { Plant, BU, Global}
   PlantViewOnly: 110,
   BusinessUnitViewOnly: 120,
   GlobalViewOnly: 130,

   // Edit Only - for { Plant, BU, Global}
   PlantEditOnly: 210,
   BusinessUnitEditOnly: 220,
   GlobalEditOnly: 230,

   // Edit and Configure - for { Plant, BU, Global}
   PlantEditAndConfigure: 310,
   BusinessUnitEditAndConfigure: 320,
   GlobalEditAndConfigure: 330,

   // System Admin - for { Plant, BU, Global}
   PlantSystemAdmin: 410,
   BusinessUnitSystemAdmin: 420,
   GlobalSystemAdmin: 430,
}

/** Threshold values for the buttons -
 *  if the RBAC_STATUS values are >= threshold value , the respective buttons are enabled
 *  if not they are disabled */

export const RBAC_THRESHOLD_VALUE = {
   Button: {
      FactoryConfig: 310,
      PlantAdmin: 410,
      SystemAdmin: 420,
      AddEditCompany: 430,
      AddEditBU: 430,
      AddEditPlant: 430,
      CompleteConfiguration: 310,
   },
}

export const RBAC_BUTTONS = {
   FACTORY_CONFIG: 'FactoryConfig',
   PLANT_ADMIN: 'PlantAdmin',
   SYSTEM_ADMIN: 'SystemAdmin',
   ADD_EDIT_COMPANY: 'AddEditCompany',
   ADD_EDIT_BU: 'AddEditBU',
   ADD_EDIT_PLANT: 'AddEditPlant',
   COMPLETE_CONFIGURATION: 'CompleteConfiguration',
}

export const EDIT_STATE_KEYS = {
   SOC_EDIT_STATE: 'socEditState',
   FACTORY_CONFIG_EDIT_STATE: 'factoryGeneralEditState',
}

export const WEB_SOCKET_CONSTANTS = {
   DEFAULT_CHANNEL: 'soc',
   RETRY_TIME: 10,
   RETRY_DELAY_IN_MILLISECOND: 500,
   ERROR_MESSAGE:
      'Something went wrong while connecting to websocket, Please Refresh Page',
}

export const DDS_CONSTANTS = {
   WSS_CHANNEL: 'dds',
   STATE: {
      PREPARING: 'Preparing',
      READY: 'Ready',
   },
}

export const FEATURE_FLAGS = {
   POS: 'POS_enable_disable',
   DT: 'DigitalTwin_enable_disable',
   SMART_SPC: 'smartSPC_enable_disable',
   CUSTOM_NOTIFICATION: 'CustomNotifications_configuration_enable_disable',
   GEN_CI: {
      VIRTUAL_ASSISTANT: 'GenCI_virtualassistant_enable_disable',
      CHAT_BOT: 'GenCI_chatbot_enable_disable',
      DOCUMENT_UPLOAD: 'GenCI_document_upload_menu',
   },
   YP: 'YP_enable_disable',
}
