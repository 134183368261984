import { instance, urlEndpoints } from './apiHelper'

async function updatePosConfig(data) {
    const result = await instance.put(urlEndpoints.UPDATE_POS_CONFIG, data)
    return result.data
}

export const posConfig = {
    updatePosConfig,
} 
    