import { instance, urlEndpoints } from './apiHelper'
import { globalstate$ } from '../smf-ui-util-app'

//-- since there is only company per tenant 
//passing a companyId as query param is redundant
async function getAllPlants() {
   const result = await instance.get(
      `${urlEndpoints.PLANTS}?companyId=${globalstate$.value.companyData.companyId}`
   )
   return result.data
}

async function getPlantData(plantId) {
   const result = await instance.get(urlEndpoints.PLANTS + '/' + plantId)
   return result.data
}

async function createPlant(data) {
   const result = await instance.post(urlEndpoints.PLANTS, data)
   return result.data
}

async function updatePlant(plantId, data) {
   const result = await instance.put(urlEndpoints.PLANTS + '/' + plantId, data)
   return result.data
}

async function getImage(params = {}) {
   const result = await instance.get(urlEndpoints.IMAGE, { params });
   return result.data
}

async function getStatesList() {
   const result = await instance.get(urlEndpoints.STATES)
   return result.data
}

export const plants = {
   getAllPlants,
   getPlantData,
   createPlant,
   updatePlant,
   getStatesList,
   getImage,
}
