/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import { instance, urlEndpoints } from './apiHelper'

export async function getEntities(params) {
   const result = await instance.get(urlEndpoints.ENTITIES, { params })
   return result.data
}

/**
 * Description: GetPreSigned URL for Equipment Intelligence Sheet
 * @module entity.js
 * @params {object} param - PlantId
 */

export async function getEquipmentIntelligenceExportDataUrl(param) {
   const result = await instance.get(urlEndpoints.EQUIPMENT_INTELLIGENCE, {
      params: { plantId: param },
   })
   return result.data
}
export async function getEquipmentIntelligencePresignedUploadUrl(params) {
   const result = await instance.post(
      urlEndpoints.EQUIPMENT_INTELLIGENCE_GET_PRESIGNED_URL,
      {
         ...params,
      }
   )
   return result.data
}
export async function uploadEquipmentIntelligence(presignedUrl, file) {
   return axios({
      url: presignedUrl,
      method: 'put',
      data: file,
      headers: { 'Content-Type': file.type },
   })
}
async function processEquipmentIntelligence(data) {
   const result = await instance.post(
      urlEndpoints.EQUIPMENT_INTELLIGENCE_PROCESS,
      data
   )
   return result.data
}
async function getEquipmentIntelligenceMetaData(params) {
   return instance.get(
      urlEndpoints.EQUIPMENT_INTELLIGENCE_META_DATA,
      { params }
   )
}

export const entity = {
   getEntities,
   getEquipmentIntelligenceExportDataUrl,
   getEquipmentIntelligencePresignedUploadUrl,
   uploadEquipmentIntelligence,
   processEquipmentIntelligence,
   getEquipmentIntelligenceMetaData,
}
