/* eslint-disable no-underscore-dangle */
import { instance, urlEndpoints } from './apiHelper'

async function getCompanyDetails(params) {
   const result = await instance.get(urlEndpoints.COMPANY, { params })
   return result.data
}

async function putCompanyName(params) {
   const result = await instance.put(urlEndpoints.COMPANY, null, { params })
   return result.data
}

async function createCompany(payload, params) {
   const result = await instance.post(urlEndpoints.ORGANIZATION, payload, {
      params,
   })

   return result.data
}

export const company = { getCompanyDetails, putCompanyName, createCompany }
