/* eslint-disable prettier/prettier */
import { globalstate$ } from '../smf-ui-util-app'
import jwt_decode from 'jwt-decode'
import {
   ACCESS_TOKEN_KEY,
   AUTH_CODE,
   BEARER_TOKEN_PREFIX,
   EXPIRE_TIME,
   REFRESH_TOKEN_KEY,
   RBAC_STATUS,
   EXTEND_LOGIN,
   ID_TOKEN,
   REDIRECT_FROM_NEW_TO_OLD,
} from '../constants'
import { instance, urlEndpoints } from './apiHelper'
import { getHighestRBACStatus } from '../rbac'

function getParamsFromURL(param = 'code') {
   const params = new URLSearchParams(location.search)
   return params.get(param)
}

async function refreshAccessToken(refreshToken) {
   const body = {
      grantType: REFRESH_TOKEN_KEY,
      clientId: process.env.COGNITO_CLIENT_ID,
   }
   const meta = {
      headers: {
         'Refresh-Token': refreshToken,
      },
   }
   const tokenResponse = await instance.post(
      urlEndpoints.AUTH_TOKEN,
      body,
      meta
   )
   const result = tokenResponse?.data
   if (result?.access_token) {
      globalstate$.next({
         ...globalstate$.value,
         sessionToken: result.token_type + ' ' + result.access_token,
         expires_in: result.accessTokenExpiry,
      })
   }
}

function getUserScopes(token) {
   const decoded = jwt_decode(token)
   const userGroups = decoded['scope'].split(' ')
   const scopes = []
   userGroups?.forEach((group) => {
      if (group.includes('/')) {
         const splitGroup = group.split('/')
         scopes.push(splitGroup.at(-1))
      }
   })
   return scopes
}

async function refreshUserLogin() {
   const refreshToken = globalstate$.value.refreshToken
   const expires_in = globalstate$.value.expires_in || 3600
   const refreshTokenExpiry = process.env.COGNITO_LOGIN_TIMEOUT || 3600
   const refresh_in = (expires_in - (expires_in > 600 ? 600 : 2)) * 1000
   setInterval(() => {
      refreshAccessToken(refreshToken)
   }, refresh_in)
   if (refreshTokenExpiry >= expires_in) {
      setTimeout(() => {
         console.debug('Logging out after refresh token expiry')
         window.location = process.env.BASE_APP_PATH + '/logout'
      }, refreshTokenExpiry * 1000)
   } else {
      console.warn(
         'Unable to configure session logout as Refresh Token timeout is not confgired. Timeout value: ',
         process.env.COGNITO_LOGIN_TIMEOUT
      )
   }
}

/**
 * This helper api is responsible to validate and
 * set(if require) the JWT Access Token in local storage.
 *
 * It looks up into the Local Storage for Access Token(JWT), if not available
 * check the current URL to determine if the call is redirecting back from the
 * Smart Factory Cognito, if not retuens false,
 * oterwise set the token into the local storage and returns true.
 *
 * @author Amlan Saha
 *
 * @returns {boolean} "true" if token available in local storage, otherwise "false"
 */

async function isloggedInUser() {
   // Flag to determine
   let isLoggedIn = false

   // Look up for existing access token in local storage
   const authToken = globalstate$.value.sessionToken
   const userData = globalstate$.value.userData

   const accessTokenNonScoped = localStorage.getItem('token')
   localStorage.removeItem('token')
   // If not present in local storage
   if (!authToken) {
      const configRedirect = getParamsFromURL(REDIRECT_FROM_NEW_TO_OLD)
      // Handle redirect from New UI.
      if (
         (configRedirect || sessionStorage.getItem('redirectToOld')) &&
         !getParamsFromURL('code')
      ) {
         window.location = process.env.COGNITO_SIGN_IN_URL.replace(
            '${COGNITO_AUTH_URL}',
            process.env.COGNITO_AUTH_URL
         )
            .replace('${COGNITO_CLIENT_ID}', process.env.COGNITO_CLIENT_ID)
            .replace(
               '${COGNITO_REDIRECT_URL}',
               process.env.COGNITO_REDIRECT_URL + '/authorize'
            )
         return
      } else {
         const code = getParamsFromURL('code')
         if (code) {
            isLoggedIn = await fetchTokenByCode(
               code,
               accessTokenNonScoped,
               isLoggedIn
            )
         }
      }
   } else {
      isLoggedIn = true
   }

   if (!userData) {
      // Setting UserData
      await updateUserDataToGlobalState(isLoggedIn)
   }

   return isLoggedIn
}

async function fetchTokenByCode(code, accessTokenNonScoped, isLoggedIn) {
   const body = {
      grantType: AUTH_CODE,
      clientId: process.env.COGNITO_CLIENT_ID,
      code: code,
      redirectUri: process.env.COGNITO_REDIRECT_URL,
   }
   const meta = {
      headers: {
         Authorization: BEARER_TOKEN_PREFIX + '' + accessTokenNonScoped,
      },
   }
   const tokenResponse = await instance.post(
      urlEndpoints.AUTH_TOKEN,
      body,
      meta
   )
   const result = tokenResponse.data
   const scope = getUserScopes(result.access_token)
   const highestRole = Object.keys(RBAC_STATUS).find(
      (key) => RBAC_STATUS[key] === getHighestRBACStatus(scope)
   )
   const extendedLoginRoles = result.extendedLoginRoles ?? []
   const isExtendedLoginDirect = extendedLoginRoles.some(
      (role) => role === highestRole
   )
   if (result.access_token && result.refresh_token) {
      isLoggedIn = true

      // Stream the access token through Global state
      globalstate$.next({
         ...globalstate$.value,
         sessionToken: result.token_type + ' ' + result.access_token,
         refreshToken: result.refresh_token,
         expires_in: result.accessTokenExpiry,
         idToken: result.id_token,
      })
      if (isExtendedLoginDirect) {
         refreshUserLogin()
      }
   }
   return isLoggedIn
}

function getTokenFromURL(key = ACCESS_TOKEN_KEY) {
   if (window.location.hash.length > 0) {
      const hash = window.location.hash.substring(1)
      const params = {}

      // Fetch all the key value tokens
      hash.split('&').forEach((hk) => {
         let temp = hk.split('=')
         params[temp[0]] = temp[1]
      })

      // Look up for Access Token
      return params[key]
   }
}

async function getAuthUserDetails(params) {
   try {
      const result = await instance.get(urlEndpoints.AUTH_USER, { ...params })
      if (
         !result ||
         !result.data ||
         !result.data.groups ||
         result.data.groups.length === 0
      ) {
         throw new Error('User group data not available!')
      }
      return result.data
   } catch (error) {
      console.error('userData error: ', error)
      console.error('userData logout')
      window.location = '/logout'
   }
}

const mapCiamGroupsToCognito = (claim) => {
   // List of synced groups in cognito from CIAM eg. "[a2333, b344]"
   const associatedGroups = claim.Value
      ? claim.Value.replace('[', '').replace(']', '').split(',')
      : [] // Array to store mapped groups for user

   const mappedGroups = [] // Object containing all the groups from Azure AD where key is group id and value is name

   const azureADMetaDataGroups = process.env.AZURE_AD_GROUP_METADATA

   associatedGroups.forEach((group) => {
      // Trim each group to get rid of extra spaces before comma (,)
      const groupValue = group ? group.trim() : '' // Populate the matching groups in response

      if (azureADMetaDataGroups[groupValue]) {
         mappedGroups.push(azureADMetaDataGroups[groupValue])
      }
   })

   return mappedGroups
}

/**
 * Method that saves the user permission at the global level using the cognito groups
 * For each plant the plant permission/role is being saved from entities api
 * @param {Boolean} isLoggedIn
 */
async function updateUserDataToGlobalState(isLoggedIn) {
   if (isLoggedIn) {
      const userData = await getAuthUserDetails({})
      const globalRoleValue = getHighestRBACStatus(userData.groups)
      /**
       * Adding Cookie Script
       */
      addCookieScript()
      // check the flag and Stream the isLoggedIn flag through Global state
      globalstate$.next({
         ...globalstate$.value,
         isLoggedInUser: isLoggedIn,
         userData,
         userStatus: globalstate$.value.plantRole
            ? getHighestRBACStatus([globalstate$.value.plantRole])
            : globalRoleValue,
      })
   } else {
      // If not logged in remove the Token from local storage and global state
      globalstate$.next({
         ...globalstate$.value,
         isLoggedInUser: isLoggedIn,
         sessionToken: '',
         idToken: '',
         userData: null,
      })
   }
}

function addCookieScript() {
   const dataDomainScript = process.env.COOKIE_BANNER_ID
   /**
    * if the cookie banner id is not found in the config file, then skipping this cookie banner stub
    */
   if (!dataDomainScript) {
      console.error('Cookie Banner ID not found!')
      return
   }
   const script = document.createElement('script')
   script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
   script.type = 'text/javascript'
   script.setAttribute('data-domain-script', dataDomainScript)
   script.nonce = 'pHSQQOVJnbkhBoZ1L43IcA=='
   document.getElementsByTagName('head')[0].appendChild(script)
}

export const authenticationGuard = {
   isloggedInUser,
   getAuthUserDetails,
   getTokenFromURL,
   getParamsFromURL,
}
