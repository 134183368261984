import UserSuggestions from "../data/UserSuggestions.json";
import UserDropDown from "../data/UserDropDown.json"
import UserDetail from "../data/UserDetail.json"

async function getUserDetails(params) {
	if(params.emailId)
		return JSON.stringify(UserDetail);
	return JSON.stringify(UserSuggestions);
}

async function getUsersDropdown() {
	return JSON.stringify(UserDropDown);
}

export const user = { getUserDetails , getUsersDropdown}
